@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, span, a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

input,
textarea {
  border: 0;
  padding: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  font-weight: 400;
}

button.ButtonArrowUp,
a.ButtonWhatsapp {
  width: 60px !important;
  height: 60px;
  padding: 0 !important;
  border-radius: 50%;
  font-size: 24px !important;
  opacity: 0;
  pointer-events: none;
  transition: all ease .5s;

  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}

a.ButtonWhatsapp {
  display: flex !important;
  background-color: #1CCC00 !important;
  justify-content: center;
  align-items: center;

  left: unset;
  right: 20px;
  bottom: 100px;
}

a.ButtonWhatsapp:hover {
  background-color: #0AAA00 !important;
}

button.ButtonArrowUp.active-scroll,
a.ButtonWhatsapp.active-scroll {
  opacity: 1;
  pointer-events: all;
}

.App {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  button.ButtonArrowUp {
    right: 40px;
    bottom: 40px;
  }

  a.ButtonWhatsapp {
    right: 40px;
    bottom: 120px;
  }
}
