@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.PromotionalDash * {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

.PromotionalDash h1, .PromotionalDash h2, .PromotionalDash h3,
.PromotionalDash h4, .PromotionalDash h5, .PromotionalDash h6,
.PromotionalDash p, .PromotionalDash span, .PromotionalDash a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  margin: 0;
}

input,
textarea,
button {
  background-color: transparent;
  resize: none;
  padding: 0;
  border: 0;
  outline: 0;
  margin: 0;
}

button {
  cursor: pointer;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}